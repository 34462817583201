@import '../../variables';
.button{
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 9;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  width: 30px;
  height: 30px;
  padding: 5px;
  box-shadow: 0 2px 5px  rgba(0,0,0,0.2);
  &.active{
    background: $buttonColor;
  }
}