.wrapper{
  margin-bottom: 10px;
}
.rating,
.notRating{
  display: flex;
  justify-content: space-between;
  color: #E0AA44;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}
.notRating{
  filter: saturate(0);
  opacity: 0.7;
}
.number{
  display: flex;
  align-items: center;
}