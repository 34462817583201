.header{
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.logo{
  max-height: 100%;
  display: flex;
  align-items: center;
}