@import "../../variables";

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  animation-name: appear;
  animation-duration: 300ms;
}
.dialog {
  width: 100%;
  max-width: 550px;
  color: $black;
  position: relative;
  margin: 0 16px;
  max-height: calc(100vh - 32px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  -webkit-animation-name: slide-in;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
}
.header,
.footer {
  display: flex;
  align-items: center;

}
.header {
  justify-content: space-between;
}
.footer {
  justify-content: flex-end;
}
.close {
  filter: invert(1);
  cursor: pointer;
  margin-bottom: 12px;
  display: flex;
  svg{
    margin: 0;
  }
}
.body {
  overflow: auto;
}

.content {
  padding: 1rem;
  background-color: $white;
  border-radius: 16px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(150px);
  }
  to {
    transform: translateY(0);
  }
}