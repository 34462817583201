@import "../../variables";

.container{
  margin: 0 10px;
  padding-bottom: 120px;
  word-wrap: break-word;
}
.avatar{
  margin: 16px 0;
}
.rating{
  *{
    font-size: 18px;
  }
  svg{
    width: 24px;
    height: 24px;
  }
}
.name{
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
.info{
  font-size: 16px;
  a{
    color: $white;
    text-decoration: none;
  }
  .items{
    border-top: 1px solid $gray;
    padding-top: 18px;
    &:last-child{
      margin-bottom: 16px;
      border-bottom: 1px solid $gray;
    }
    .item{
      font-weight: 400;
      margin-bottom: 20px;
      &.category{
        font-weight: 600;
      }
      &.child{
        margin-top: -10px;
        font-size: 14px;
        color: $gray;
        svg{
          width: 16px;
          min-width: 16px;
          margin-left: 4px;
          margin-right: 14px;
        }
      }
      &.link{
        margin-bottom: 18px;
        color: $gray;
      }
    }
  }
  .item{
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    &:last-child{
      margin-bottom: 18px;
    }
  }
  .title{
    font-size: 14px;
    color: $gray;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &+div{
      margin-bottom: 16px;
      white-space: pre-wrap;
    }
  }
  svg{
    margin-right: 10px;
    width: 24px;
    min-width: 24px;
  }
}
.back{
  margin: 40px;
}
.backLink{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  text-decoration: none;
  margin: 0 auto;
  font-weight: 500;
  text-shadow: 1px 1px 10px #000000b8, 0 0 15px #000000b8, 0 0 2px #00000099;
  border-radius: 10px;
  color: $white;
  padding: 10px 10px 0;
  display: block;
  svg{
    margin-right: 10px;
    path{
      stroke: $white;
    }
  }
}