@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
body {
  margin: 0 auto;
  max-width: 500px;
  font-family: 'Montserrat', sans-serif;
  background: #000000;
  color: #ffffff;
  overflow-x: hidden;
}
input,
button{
  outline: none;
  font-family: 'Montserrat', sans-serif;
  &:focus,
  &:hover,
  &:active{
    outline: none;
  }
}
.loader{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 70px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea, select {
  font-size: 16px!important;
}