@import "../../variables";

.container{
  margin: 0 16px;
}
.loader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  .field{
    margin: 0;
  }
  .button{
    margin-left: 8px;
  }
}
.textarea{
  font-family: 'Montserrat', sans-serif;
  min-width: 100%;
  max-width: 100%;
  min-height: 35px;
}
.button{
  background-color: $buttonColor;
  color: $light;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  border-radius: 6px;
  padding: 9px 16px;
  cursor: pointer;
  svg{
    margin-right: 12px;
  }
}
button{
  outline: none;
  border: none;
  width: 100%;
}
.inform{
  font-size: 12px;
  color: #9C978F;
  margin-bottom: 16px;
}
.label{
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  color: $buttonColor;
}
.field,.textarea{
  padding: 6px 12px 6px 10px;
  border-radius: 6px;
  outline: none;
  border: none;
  width: 100%;
  font-weight: 500;
  line-height: 24px;
  box-sizing: border-box;
  margin-bottom: 16px;
  &::placeholder{
    color: #9C978F;
  }
}
.textarea{
  font-family: 'Montserrat', sans-serif;
  min-width: 100%;
  max-width: 100%;
  min-height: 35px;
  height: 180px;
}
.inputs {
  background: rgba(255, 255, 255, 0.15);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 16px;
  .buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .button{
    cursor: pointer;
    text-align: center;
    padding: 9px 16px;
    border-radius: 6px;
    width: 100%;
    background: $buttonColor;
    margin: 5px;
    &.remove{
      background: #ee3737;
      color: $white;
    }
  }
}
.checkbox{
  position: absolute;
  z-index: -1;
  opacity: 0;
  &+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    margin: 16px 0 32px 0;
    &::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 55% 55%;
    }
  }
  &:checked{
    &+label {
      &::before {
        border-color: $buttonColor;
        background-color: $buttonColor;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 9'%3e%3cpath fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M10.6666 1.5L4.24998 7.91667L1.33331 5'/%3e%3c/svg%3e");
      }
    }
  }
}