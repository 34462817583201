.swiper{
  padding: 10px;
}
.slide{
  border-radius: 20px;
  background: #ffffff;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc( 100vw - 150px );
  max-height: 280px;
  overflow: hidden;
  img,a{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}