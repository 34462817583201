@import "./variables";
.react-select{
  .react-select {
    &__control{
      background: $white;
      outline: none;
      box-shadow: none;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      box-sizing: border-box;
      margin-bottom: 16px;
      &--is-focused,
      &--is-selected{
        background: $white;
      }
    }
    &__option {
      color: $black;
      &--is-focused,
      &--is-selected,
      &:hover,
      &:active,
      &:focus-visible{
        outline: none;
        background: $light;
      }
    }
  }
}