@import "../../variables";

.faq{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
  z-index: 2;
}
.title{
  font-size: 16px;
  font-weight: 600;
}
.icon{
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card{
  background: rgba(255, 237, 202, 0.95);
  border-radius: 12px 0px 12px 12px;
  color: $black;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  z-index: 99;
  top: 100%;
  margin-top: 8px;
  right: 15px;
  width: calc( 100% - 25px);
}
.content{
  padding: 10px 16px;
}
.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}