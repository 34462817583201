@import '../../variables';

.actions{
  background: $white;
  position: fixed;
  bottom: 0;
  max-width: 500px;
  width: calc( 100% - 20px );
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  padding: 10px 10px 20px;
  svg{
    margin-right: 10px;
  }
}
.button{
  background: $buttonColor;
  color: $white;
  font-family: inherit;
  padding: 10px;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.message{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  svg{
    margin-right: 20px;
  }
}