.notFound{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  flex-direction: column;
  margin-top: 20px;
  svg{
    margin: 24px;
  }
}
.text{
  font-size: 14px;
  color: #9C978F;
  margin: 0 30px;
  text-align: center;
}